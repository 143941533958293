import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Wrapper,
  Title,
  PerformerContaier,
  ProductionsList,
  ListItem,
  // ViewLink,
  BottomPaddingBlock,
  ImgTitleOp
} from "./performerStyles"
import {
  DescriptionPara,
  VerticalLine,
  VerticalLineContainer,
} from "../commons/ImageDesc"
import {
  PosterContainer,
  PosterImageContainer,
  PosterTitle,
} from "../commons/PosterContainer.style"
import {
  CardTitle,
  ImageCardContainer,
  ImageContainer,
  ImageFadeIn,
  ImageScaleTransition,
  ImgDesc
} from "../commons/HighlightsCards.style"


const Performer = () => (
  <React.Fragment>
    <PerformerContaier className="container">
      <PosterContainer backgroundColor="#000000">
        <PosterTitle>PERFORMER-CHOREOGRAPHER</PosterTitle>
        <PosterImageContainer width="715px">
          <ImageFadeIn>
            <StaticImage
              src="../../images/Performer/performer.svg"
              alt="performer"
              placeholder="dominantColor"
            />
            <CardTitle>
              Grace, precision, and subtle
              <br /> expression
            </CardTitle>
          </ImageFadeIn>
        </PosterImageContainer>

        <DescriptionPara>
          Rajyashree’s presentations since early performances reflect a deep
          involvement as performer. As a performer-choreographer, she has
          conceived and presented several stage productions and commissioned
          work since the 1990’s both with her performing company Rasika Dance
          Ensemble and international artists. Funded by state grants in Berlin
          for her innovative choreographic ideas, she staged many a signature
          production with her later Rasika Dance Theatre International as a
          classical-innovative, multinational/cross-cultural or
          multidisciplinary venture, often with live musical score. She has also
          worked as guest choreographer in several unique multi-genre theatre
          productions.
        </DescriptionPara>

        <DescriptionPara>
          <div>Some signature productions: </div>
          <ProductionsList>
            <ListItem>
              ”Goethe's Iphigenie" with actress Erika Eller (premiere funded by
              District Cultural Department of Berlin-Schoneberg in 1998 with
              repeat performances in several formats)
            </ListItem>
            <ListItem>
              “Rainbow Melodies” with Indian musicians (funded by District
              Cultural Department of Berlin- Steglitz-Zehlendorf 2001)
            </ListItem>
            <ListItem>
              “Jalakrida - the Water Play” (commissioned for Asia-Pacific Weeks
              2005)
            </ListItem>
            <ListItem>
              “Körpersprachen-Mundarten” (with international artists, funded by
              District Cultural Department of Berlin-Schöneberg in 2007)
            </ListItem>
            <ListItem>
              “Epos-Ramayana” (choreographic assignment for State Theater
              Chemnitz 2004-2005)
            </ListItem>
            <ListItem>
              “Rasa - the dewdrop of experience” (Indo-Lithuanian production
              2018)
            </ListItem>
            <ListItem>
              “Sommernachtstraum” (choreographic assignment for Possible World
              e.V. 2017-2019) 
            </ListItem>
          </ProductionsList>
          {/* <ViewLink
            target="_blank"
            href={"http://www.rr-dance.net/de/academy.html"}
          >
            View complete list
          </ViewLink> */}
        </DescriptionPara>
        <BottomPaddingBlock />
      </PosterContainer>
      <VerticalLineContainer>
        <VerticalLine />
      </VerticalLineContainer>
      <Wrapper>
        <Title>
          Grace, precision, subtle expression, eloquence in communication,
          clarity in concept, a penchant for narratives and an inherent
          knowledge of music.
        </Title>

        <ImageCardContainer gap="0px">
          <ImageContainer>
            <ImageScaleTransition>
              <StaticImage
                src="../../images/Performer/1960.svg"
                alt="researcher"
                placeholder="dominantColor"
              />
              <CardTitle>
                <ImgTitleOp>1960s-1970s</ImgTitleOp>
                <ImgDesc>Early performances</ImgDesc>
              </CardTitle>
            </ImageScaleTransition>
          </ImageContainer>

          <ImageContainer>
            <ImageScaleTransition>
              <StaticImage
                src="../../images/Performer/1980.svg"
                alt="researcher"
                placeholder="dominantColor"
              />
              <CardTitle>
                <ImgTitleOp>1980s</ImgTitleOp>
                <ImgDesc>Launching novel</ImgDesc>
                <ImgDesc>teaching</ImgDesc>
                <ImgDesc>methodologies</ImgDesc>
              </CardTitle>
            </ImageScaleTransition>
          </ImageContainer>

          <ImageContainer>
            <ImageScaleTransition>
              <StaticImage
                src="../../images/Performer/1990.svg"
                alt="researcher"
                placeholder="dominantColor"
              />
              <CardTitle>
                <ImgTitleOp>1990s</ImgTitleOp>
                <ImgDesc>Launching Rasika</ImgDesc>
                {/* <ImgDesc>Performing</ImgDesc>
                <ImgDesc>platform  for</ImgDesc> */}
                <ImgDesc>classical-innovative</ImgDesc>
                <ImgDesc>choreographies</ImgDesc>
              </CardTitle>
            </ImageScaleTransition>
          </ImageContainer>

          <ImageContainer>
            <ImageScaleTransition>
              <StaticImage
                src="../../images/Performer/2000.svg"
                alt="researcher"
                placeholder="dominantColor"
              />
              <CardTitle>
                <ImgTitleOp>Since 2000s</ImgTitleOp>
                <ImgDesc>Multinational</ImgDesc>
                <ImgDesc>multi-disciplinary</ImgDesc>
                <ImgDesc>stage productions</ImgDesc>
              </CardTitle>
            </ImageScaleTransition>
          </ImageContainer>
        </ImageCardContainer>
      </Wrapper>
    </PerformerContaier>
  </React.Fragment>
)

export default Performer
