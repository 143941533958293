import * as React from "react"

import Layout from "../components/layout"
import Performer from "../components/performer"
import Seo from "../components/seo"

const logo = require("../images/whitelogo.svg").default

const performerPage = () => (
  <Layout
    backgroundColor="black"
    logo={logo}
    menuBGColor="black"
    hamburgerColor="white"
    footerColor="black"
  >
    <Seo title="Rajyashree Ramesh" />
    <Performer />
  </Layout>
)

export default performerPage
